import React from "react"

const NotFoundPage = () => (
<html>
  <head>
<style>
p {"font-family: Georgia;"}
</style>
</head>
    <center>
    {/* <img src="../images/sisyphus.png" alt="sisyphus"/> */}
    <p style={{fontFamily: 'Georgia', fontSize: '72px'}}>brb currently pushing a boulder up a hill (I don't know why its taking so long...)</p>
    </center>
</html>
)


export default NotFoundPage
